import React, { memo, ReactElement, Suspense } from "react";
import { footerBlockRenderer, kameleonBlockRenderer } from "kameleon-elements";
import { KameleonFooterWidget } from "@kameleon-util/manifest";
import { isEqual } from "lodash";
import { Block } from "@kameleon-gatsby/gatsby-kameleon-data-source/types";

type DynamicLoaderProps = {
  component: string;
  blockProps?: any;
  isFooter?: boolean;
  footerWidgets?: KameleonFooterWidget[];
  secondaryBlock?: Block;
  tertiaryBlock?: Block;
};

function DynamicLoader({
  component,
  blockProps,
  secondaryBlock,
  tertiaryBlock,
  isFooter,
  footerWidgets,
}: DynamicLoaderProps): ReactElement {
  return (
    <Suspense fallback={<div />}>
      {isFooter
        ? footerBlockRenderer(component, {
            blockProps,
            widgetBlocks: footerWidgets,
          })
        : kameleonBlockRenderer(component, {
            secondaryBlockPackageName: secondaryBlock?.packageName,
            secondaryBlockProps: secondaryBlock?.blockProps,
            tertiaryBlockPackageName: tertiaryBlock?.packageName,
            tertiaryBlockProps: tertiaryBlock?.blockProps,
            blockProps,
          })}
    </Suspense>
  );
}

export default memo(DynamicLoader, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps)
);

DynamicLoader.defaultProps = {
  blockProps: null,
  secondaryBlock: null,
  tertiaryBlock: null,
  isFooter: false,
  footerWidgets: null,
};
