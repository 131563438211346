import axios from "axios";
export const gatewayUrl = (): string => process.env.KAMELEON_GATEWAY_HOST;

export const token = (): string => process.env.KAMELEON_GATEWAY_ERROR_TOKEN;

export const gatewayErrorPath = (): string => {
  const path = process.env.KAMELEON_GATEWAY_ERROR_PATH;
  return `${path}`;
};

export const gatewayErrorUrl = (): string => {
  const url = `${gatewayUrl()}/${gatewayErrorPath()}`;
  return url;
};

export class ErrorHandlingService {
  async logError(errorData: any): Promise<void> {
    const url = gatewayErrorUrl();
    try {
      const response = await axios.post(url, errorData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Kameleon-token": `${token()}`,
        },
      });
    } catch (error: any) {
      console.error(
        `Error while logging error: ${error?.message} (${error?.code})`,
        error
      );
    }
  }
}
