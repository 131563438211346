import { ErrorHandlingService } from "./ErrorHandelingService";
// eslint-disable-next-line import/no-cycle

export class ErrorLogger {
  private errorHandlingService: ErrorHandlingService;

  constructor() {
    this.errorHandlingService = new ErrorHandlingService();
  }

  async logError(requestError: {
    name: string;
    code: number;
    message: string;
    trace?: string;
    userId?: string;
  }): Promise<void> {
    try {
      // Create an ErrorMetaData object from the request error
      const errorMetaData = {
        name: requestError.name,
        code: requestError.code,
        message: requestError.message,
        trace: requestError.trace || null, // Use null as default if 'trace' is not provided
        userId: requestError.userId || null, // Use null as default if 'userId' is not provided
      };
      await this.errorHandlingService.logError(errorMetaData);
    } catch (error: any) {
      console.error(
        `Error while logging error:: ${error?.message} (${
          error?.code ?? "unknown"
        }`
      );
    }
  }
}
