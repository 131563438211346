export const Codes = {
  jsError: {
    title: "Er is een fout opgetreden",
    description:
      "Probeer het later nog eens. Neem contact met ons op als het probleem aanhoudt.",
  },
  noConnection: {
    title: "Geen connectie mogelijk",
    description:
      "Probeer later nog eens de code toe te voegen. Neem contact met ons op als het probleem aanhoudt.",
  },
  buttons: {
    enterCode: "Code invoeren",
  },
  placeHolderCreateStory: {
    description:
      "Dit is een nieuwe pagina, het verhaal op deze pagina kan je verder vertellen in de app.",
  },
};
