export const Uris = {
  secondScreen: {
    home: (): string => process.env.KAMELEON_SECOND_SCREEN_RETURN_URL ?? "",
  },
  gatsby: {
    // Dynamically lookup the websocket url which is used to transport updated for the HMR
    hotReloadWsUrl: (): string => {
      const port = window.location.port ? `:${window.location.port}` : "";
      const protocol = window.location.protocol.startsWith("https")
        ? "wss://"
        : "ws://";
      return `${protocol}${window.location.hostname}${port}/socket.io/?EIO=4&transport=websocket`;
    },
  },
};
